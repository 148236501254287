<template>
  <div class="x-main-side-bar">
    <div
      class="x-menu-provider js-tab-menu-provider -button-pattern -desktop-view"
    >
      <div class="nav-menu" id="navbarCategory">
        <ul
          class="nav nav-pills js-menu-container -nav-menu-container flex-wrap"
        >
          <li class="nav-item">
            <a
              href=""
              class="-line-wrapper"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/main_logo.png"
                class="-img"
                alt=""
                width="100%"
                height="210"
                id="imageLogo"
              />
            </a>
          </li>

          <li class="nav-item" v-for="item in tabs" :key="item.id">
            <router-link class="nav-link-tab"  :to="item.link" tag="button" v-if="item.name_en!='E-SPORT' && item.name_en!='Sood'">
              
              <img
                :src="item.image"
                class="img-fluid -menu"
                width="55"
                height="55"
              />
              <div class="-text-provider-wrapper">
                <div class="-text-nav-menu -title">{{ item.name_en }}</div>
                <div class="-text-nav-menu -title-trans">
                  {{ item.name_la }}
                </div>
                <!-- <div class="-text-nav-menu -title-mobile">{{ item.name_la }}</div> -->
              </div>
            </router-link>
            <button class="nav-link-tab"   v-else target="_blank"    onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
              
              <img
                :src="item.image"
                class="img-fluid -menu"
                width="55"
                height="55"
              />
              <div class="-text-provider-wrapper">
                <div class="-text-nav-menu -title">{{ item.name_en }}</div>
                <div class="-text-nav-menu -title-trans">
                  {{ item.name_la }}
                </div>
                <!-- <div class="-text-nav-menu -title-mobile">{{ item.name_la }}</div> -->
              </div>
            </button>
          </li>
        </ul>
        <div class="-contact-wrapper">
          <div class="x-contact-us -text">
            <a
              href="https://lin.ee/BDvR8q2"
              class="-link-wrapper -line-wrapper"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="rich-gaming Image"
                class="img-fluid -line-img lazyloaded"
                data-src="https://richgaming.co/build/web/ezc-rich-gaming/img/contact-us.png?v=6"
                src="https://richgaming.co/build/web/ezc-rich-gaming/img/contact-us.png?v=6"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          name_la: "ยอดนิยม",
          name_en: "HOT GAME",
          image:
          require("@/assets/assets_np8/hot_game.png"),
          link: "popular",
        },
        {
          id: 2,
          name_la: "คาชิโน",
          name_en: "CASINO",
          image:
          require("@/assets/assets_np8/casino.png"),
          link: "casino",
        },
        {
          id: 3,
          name_la: "สล็อต",
          name_en: "SLOT",
          image:
          require("@/assets/assets_np8/slot.png"),
          link: "slot",
        },
        {
          id: 4,
          name_la: "กีฬา",
          name_en: "SPORT",
          image:
          require("@/assets/assets_np8/sport.png"),
          link: "sport",
        },
        {
          id: 5,
          name_la: "ยิงปลา",
          name_en: "FISHING",
          image:
          require("@/assets/assets_np8/fish.png"),
          link: "fishing",
        },
        {
          id: 6,
          name_la: "สกิลเกมส์",
          name_en: "SKILL GAME",
          image:
          require("@/assets/assets_np8/skill_game.png"),
          link: "skill",
        },
        {
          id: 7,
          name_la: "อีสปอร์ต",
          name_en: "E-SPORT",
          image:
          require("@/assets/assets_np8/e_sport.png"),
          link: "https://np8.co/login",
        },
        {
          id: 8,
          name_la: "กลยุทธ์",
          name_en: "Sood",
          image:
          require("@/assets/assets_np8/plan.png"),
          link: "https://np8.co/login",
        },
      ],
    };
  },
};
</script>

<style scoped>
.x-contact-us.-text .-line-wrapper .-line-img,
.x-contact-us.-text .-live-chat-wrapper .-img {
  width: 160px;
  height: auto;
}

.x-menu-provider .-contact-wrapper {
  padding: 2rem 1rem;
}
.x-menu-provider .nav-menu {
  display: flex;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}

.x-menu-provider .-nav-menu-container {
  width: 100%;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
  padding-top: 75px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-width: 991.98px) {
  .x-menu-provider .nav-menu {
    width: auto;
    background: transparent;
  }
  .x-main-container .x-main-side-bar {
    display: none;
}
}

@media (min-width:1481px){

  .x-menu-provider .-nav-menu-container {
    padding-top:0px;
  }

  #imageLogo {
    height:260px;
  }

}


@media (min-width: 992px) {
  .x-menu-provider.-desktop-view {
    z-index: 1;
    position: fixed;
    top: 75px;
    left: 0;
    width: 250px;
    height: calc(100vh - 75px);
    overflow: auto;
  }
  .x-menu-provider {
    border-right: 1px solid red;
    background: #610B0B
  }
  .x-menu-provider.-desktop-view .nav-menu {
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.4s;
    z-index: 1;
  }
}
@media (max-width: 1480px) {
  .x-menu-provider .nav-link-tab {
    padding: 0.75rem 0.5rem;
  }
  .x-menu-provider.-desktop-view {
    width: 200px;
  }
  .x-menu-provider .-nav-menu-container {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.x-menu-provider .nav-item {
  width: 100%;
  margin-top: 1rem;
}

/* nav tab */
.nav-link-tab {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
  background: linear-gradient(180deg, #0e2783, #0b132f);
  width: 100%;
  position: relative;
  border-radius: 14px;
  color: hsla(0, 0%, 100%, 0.8);
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.nav-link-tab:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
  background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}

.x-menu-provider .nav-link-tab .-text-provider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.75rem;
}
.x-menu-provider .nav-link-tab .-text-nav-menu.-title {
  display: block;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
}
.x-menu-provider .nav-link-tab .-text-nav-menu.-title,
.x-menu-provider .nav-link-tab .-text-nav-menu.-title-trans {
  font-size: 0.875rem;
}

/* end nav tab */

/* logo */
.nav-item .-line-wrapper .-img:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

/* end logo */

/* custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #b30000;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #ff6666;
}
/* end custom scrollbar */
*,
:after,
:before {
  box-sizing: border-box;
}

button.router-link-active {
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border: none;
    background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}



</style>