<template>
  <div class="x-main-content">
    <div class="-full-flex-wrap x-bg-position-center lazyloaded">
      <div
        class="-full-flex-inner-bg -bg x-bg-position-center lazyloaded"
        
      >
        <div class="d-none d-lg-block"></div>

        <div class="-index-inner-wrapper">
          <div class="banner"  v-show="$route.path!=='/promotion'">
            <Banners />
          </div>

          <div class="-games-provider-wrapper">
            <div class="tabs d-lg-none sticky-top" style="top: 70px; z-index:0">
            
              <ul class="nav d-flex flex-column">
                <li class="nav-item " v-for="item in tabs" :key="item.id" >
                  <router-link
                  v-if="item.name_en!='E-SPORT' && item.name_en!='Sood'"
                    class="nav-link -ยอดนิยม"
                    tag="button"
               
                    :to="item.link"
                  >
                    <div class="-button-inner-wrapper">
                      <img
                        alt="Provider menu hot game"
                        class="-ic-menu img-fluid"
                        width="55"
                        height="55"
                        :src=item.image
                      />
                      <div class="-text-provider-wrapper">
                        <!-- <div class="-text-nav-menu -title">{{ item.name_en }}</div> -->
                        <div class="-text-nav-menu -title-trans">
                          {{ item.name_la }}
                        </div>
                        <!-- <div class="-text-nav-menu -title-mobile">{{ item.name_la }}</div> -->
                      </div>
                    </div>
                  </router-link>
                  <button
                 v-else
              
                    class="nav-link -ยอดนิยม"
        
            onclick="window.open('https://np8.co/login');" style="cursor: pointer;"
            target="_blank"
                  >
                    <div class="-button-inner-wrapper">
                      <img
                        alt="Provider menu hot game"
                        class="-ic-menu img-fluid"
                        width="55"
                        height="55"
                        :src=item.image
                      />
                      <div class="-text-provider-wrapper">
                        <!-- <div class="-text-nav-menu -title">{{ item.name_en }}</div> -->
                        <div class="-text-nav-menu -title-trans">
                          {{ item.name_la }}
                        </div>
                        <!-- <div class="-text-nav-menu -title-mobile">{{ item.name_la }}</div> -->
                      </div>
                    </div>
                  </button>
              
             
                  
                </li>
              </ul>
         
            </div>
            <div class="content">
              <router-view></router-view>
              <!-- <GameIndexPageVue/> -->
            </div>
          
          </div>
        </div>
        <div class="live_feed">
              <LiveFeed />
            </div>
        <div class="footer">
              <Footers />
            </div>

            <a href="https://lin.ee/BDvR8q2" class="float" target="_blank">
<img class="my-float" src="@/assets/assets_np8/float_action_button.png"/>
</a>
      </div>
    </div>
  </div>
</template>



<script>
import Banners from "../components/Banner.vue";

// import GameIndexPageVue from "./GameIndexPage.vue";
import Footers from "../components/Footer.vue";
import LiveFeed from "../components/LiveFeed.vue";
export default {
  methods:{
    newPage(){
    
window.open('https://np8.co/login', '_blank');
this.$router.resolve({name: 'popular', query: {data: "someData"}});
    }
  },
  name: "body-main",
  components: {
    Banners,
    // GameIndexPageVue,
    Footers,
    LiveFeed
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name_la: "ยอดนิยม",
          name_en: "HOT GAME",
          image:
          require("@/assets/assets_np8/hot_game.png"),
          link: "popular",
        },
        {
          id: 2,
          name_la: "คาชิโน",
          name_en: "CASINO",
          image:
          require("@/assets/assets_np8/casino.png"),
          link: "casino",
        },
        {
          id: 3,
          name_la: "สล็อต",
          name_en: "SLOT",
          image:
          require("@/assets/assets_np8/slot.png"),
          link: "slot",
        },
        {
          id: 4,
          name_la: "กีฬา",
          name_en: "SPORT",
          image:
          require("@/assets/assets_np8/sport.png"),
          link: "sport",
        },
        {
          id: 5,
          name_la: "ยิงปลา",
          name_en: "FISHING",
          image:
          require("@/assets/assets_np8/fish.png"),
          link: "fishing",
        },
        {
          id: 6,
          name_la: "สกิลเกมส์",
          name_en: "SKILL GAME",
          image:
          require("@/assets/assets_np8/skill_game.png"),
          link: "skill",
        },
        {
          id: 7,
          name_la: "อีสปอร์ต",
          name_en: "E-SPORT",
          image:
          require("@/assets/assets_np8/e_sport.png"),
        //  link: "https://np8.co/login",
        },
        {
          id: 8,
          name_la: "กลยุทธ์",
          name_en: "Sood",
          image:
          require("@/assets/assets_np8/plan.png"),
        //  link: "https://np8.co/login",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width:997px){
  .float{
    display: none;
  }
}
@media (min-width:998px){
  .float{
	bottom:40px;
	right:40px;

	color:#FFF;
	border-radius:50px;
	
}

.my-float{
  width: 120px;
  height: auto;
  bottom:40px;
	right:40px;
  position:fixed;
  margin-left: 100px;
	margin-top:22px;
}
}
.x-main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-top: 75px;
  background-image: url('@/assets/assets_np8/bg_red.png');
  background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.x-main-content .-full-flex-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

}




@media (max-width: 768px) {
  .tabs {
    overflow-y:auto;
    width: 90px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 15px;
    float: left;
    font-style: italic;
    background: hsl(0, 80%, 21%);
  }
}
@media (min-width: 769px) {
  .tabs {
    overflow-y:auto;
    width: 18%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    float: left;
    font-style: italic;
    background: hsl(0, 80%, 21%);
  }
}

@media (min-width: 992px) {
  .content {
    padding: 0px 0px;
    margin-left: 200px;
  }
}
@media (min-width: 1200px) {
  .content {
    padding: 0px 0px;
    margin-left: 250px;
  }
}

/* nav tab */
.nav-link {
  margin-top: 8px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
  background: linear-gradient(180deg, #0e2783, #0b132f);
  width: 100%;
  position: relative;
  border-radius: 14px;
  color: hsla(0, 0%, 100%, 0.8);
}

.nav-link:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
  background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}
button.router-link-active {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
  background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}

.nav-link .-text-provider-wrapper {
  color: #fff;
  font-weight: 400;
}
.nav-link .-text-nav-menu.-title {
  display: block;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-link .-text-nav-menu.-title,
.-text-nav-menu.-title-trans {
  font-size: 0.65rem;
    

}
</style>
