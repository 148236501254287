<template>
  <div id="app">
    <router-view/>
  
  </div>
</template>

<script>
/* eslint-disable */
import GamePromotions from './components/GamePromotions.vue'
export default{
  components:{
    GamePromotions
  }
}
</script>

<style>
#app {
  font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
