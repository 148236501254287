<template>
  <div class="-games-index-page">
    <div class="js-replace-heading-sub-title-seo-container">
      <h2 class="-heading-sub-title" >
        ฝากถอนไม่มีขั้นต่ำ ถอนสูงสุด 10 ล้านบาทต่อวันไม่จำกัดจำนวนครั้ง
      </h2>
    </div>

    <div class="x-category-provider js-game-scroll-container js-game-container">
      <div class="-games-list-container" style="margin-top: 10px">
        <div class="nav-menu" id="navbarProvider">
          <div class="-inner-heading-wrapper">
            <div class="-hot-game-title-wrapper">
              <img
                alt="คาสิโนออนไลน์ ยอดนิยม"
                class="-title-bg img-fluid"
                width="200"
                height="71"
                src="@/assets/assets_np8/hot_casino_v2.png"
              />
              <!-- <span class="-title">HOT CASINO</span> -->
            </div>
          </div>
          <ul class="nav nav-pills">

            <li class="nav-item -game-casino-macro-container">
              <div
                class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                data-status
             
              >
                <div class="-inner-wrapper"
                
                onclick="window.open('https://np8.co/login');" style="cursor: pointer;"

                >
                  <div
                    class="
                      x-game-badge-component
                      -popular
                      -big
                      animated
                      fadeInUp
                    "
                    data-animatable="fadeInUp"
                    data-delay="400"
                    
                  >
                    <span>Popular</span>
                  </div>
                  <video
              
                    playsinline
                    loop
                    muted
                    autoplay
                    class="img-fluid -cover-img -cover-video"
                    __idm_id__="630785"
                  >
                    <source
                      src="@/assets/gamesIcon/slot/ezc-wt-wm-animation.mp4"
                      type="video/webm"
                    />
                    <source
                      src="@/assets/gamesIcon/slot/ezc-wt-wm-animation.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <div class="-overlay">
                    <div class="-overlay-inner">
                      <div class="-wrapper-container">
                        <a
                          href="#loginModal"
                          class="js-account-approve-aware -btn -btn-play"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <i class="fas fa-play"></i>
                          <span class="-text-btn">เข้าเล่น</span>
                        </a>
                        <a
                          href="https://np8.co/login"
                          target="_blank"
                          class="-btn -btn-demo"
                        
                          rel="nofollow noopener"
                        >
                          <span class="-text-btn">Free trial</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="-title">WM Gaming</div>
              </div>
            </li>
            <li class="nav-item -game-casino-macro-container">
              <div
                class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                data-status
              >
                <div class="-inner-wrapper"
                onclick="window.open('https://np8.co/login');" style="cursor: pointer;"
                >
                  <div
                    class="
                      x-game-badge-component
                      -popular
                      -big
                      animated
                      fadeInUp
                    "
                    data-animatable="fadeInUp"
                    data-delay="400"
                  >
                    <span>Popular</span>
                  </div>
                  <video
                    playsinline
                    loop
                    muted
                    autoplay
                    class="img-fluid -cover-img -cover-video"
                    __idm_id__="630785"
                  >
                    <source
                      src="https://asset.cloudigame.co/build/admin/img/wt-sa-gaming/ezc-wt-sa-gaming-animation.webm?v=6"
                      type="video/webm"
                    />
                    <source
                      src="https://asset.cloudigame.co/build/admin/img/wt-sa-gaming/ezc-wt-sa-gaming-animation.mp4?v=6"
                      type="video/mp4"
                    />
                  </video>
                  <div class="-overlay">
                    <div class="-overlay-inner">
                      <div class="-wrapper-container">
                        <a
                          href="#loginModal"
                          class="js-account-approve-aware -btn -btn-play"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <i class="fas fa-play"></i>
                          <span class="-text-btn">เข้าเล่น</span>
                        </a>
                        <a
                          href="/lobby/test?channelCode=wt-sa-gaming"
                          class="-btn -btn-demo"
                          target="_blank"
                          rel="nofollow noopener"
                        >
                          <span class="-text-btn">Free trial</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="-title">SA Gaming</div>
              </div>
            </li>


            <li class="nav-item -game-casino-macro-container">
              <div
                class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                data-status
              >
                <div class="-inner-wrapper"
                onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                  <div
                    class="
                      x-game-badge-component
                      -popular
                      -big
                      animated
                      fadeInUp
                    "
                    data-animatable="fadeInUp"
                    data-delay="400"
                  >
                    <span>Popular</span>
                  </div>
                  <video
                    playsinline
                    loop
                    muted
                    autoplay
                    class="img-fluid -cover-img -cover-video"
                    __idm_id__="630785"
                  >
                    <source
                      src="@/assets/gamesIcon/slot/ezc-wt-aesexy-animation.webm"
                      type="video/webm"
                    />
                    <source
                      src="@/assets/gamesIcon/slot/ezc-wt-aesexy-animation.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <div class="-overlay">
                    <div class="-overlay-inner">
                      <div class="-wrapper-container">
                        <a
                          href="#loginModal"
                          class="js-account-approve-aware -btn -btn-play"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <i class="fas fa-play"></i>
                          <span class="-text-btn">เข้าเล่น</span>
                        </a>
                        <a
                          href="/lobby/test?channelCode=wt-sa-gaming"
                          class="-btn -btn-demo"
                          target="_blank"
                          rel="nofollow noopener"
                        >
                          <span class="-text-btn">Free trial</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="-title">AE Sexy Gaming</div>
              </div>
            </li>

    
            <li class="nav-item -game-casino-macro-container">
              <div
                class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                data-status=""
              >
                <div class="-inner-wrapper"
                onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                  <picture
                    ><source
                      type="image/webp"
                      data-srcset="@/assets/gamesIcon/dreamgaming.webp" />
                    <source
                      type="image/png"
                      data-srcset="@/assets/gamesIcon/dreamgaming.png" />
                    <img
                      alt="wt-dream-gaming cover image png"
                      class="img-fluid lazyload -cover-img"
                      width="364"
                      height="231"
                      data-src="@/assets/gamesIcon/dreamgaming.png"
                      src="@/assets/gamesIcon/dreamgaming.png"
                  /></picture>
                  <div class="-overlay">
                    <div class="-overlay-inner">
                      <div class="-wrapper-container">
                        <a
                          href="#loginModal"
                          class="js-account-approve-aware -btn -btn-play"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <i class="fas fa-play"></i>
                          <span class="-text-btn">เข้าเล่น</span>
                        </a>
                        <a
                          href="/lobby/test?channelCode=wt-dream-gaming"
                          class="-btn -btn-demo"
                          target="_blank"
                          rel="nofollow noopener"
                        >
                          <span class="-text-btn">ทดลองเล่น</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="-title">Dream Gaming</div>
              </div>
            </li>
            <li class="nav-item -game-casino-macro-container">
              <div
                class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                data-status=""
              >
                <div class="-inner-wrapper"
                onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                  <picture
                    ><source
                      type="image/webp"
                      data-srcset="@/assets/gamesIcon/slot/ezc-wt-eg-direct-LightningBac0001.webp" />
                    <source
                      type="image/png"
                      data-srcset="@/assets/gamesIcon/slot/ezc-wt-eg-direct-LightningBac0001.png" />
                    <img
                      alt="wt-pretty-gaming cover image png"
                      class="img-fluid lazyload -cover-img"
                      width="364"
                      height="231"
                      data-src="@/assets/gamesIcon/slot/ezc-wt-eg-direct-LightningBac0001.png"
                      src="@/assets/gamesIcon/slot/ezc-wt-eg-direct-LightningBac0001.png"
                  /></picture>
                  <div class="-overlay">
                    <div class="-overlay-inner">
                      <div class="-wrapper-container">
                        <a
                          href="#loginModal"
                          class="js-account-approve-aware -btn -btn-play"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <i class="fas fa-play"></i>
                          <span class="-text-btn">เข้าเล่น</span>
                        </a>
                        <a
                          href="/lobby/test?channelCode=wt-pretty-gaming"
                          class="-btn -btn-demo"
                          target="_blank"
                          rel="nofollow noopener"
                        >
                          <span class="-text-btn">ทดลองเล่น</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="-title">Pretty Gaming</div>
              </div>
            </li>
        
          </ul>
          <div class="-inner-heading-wrapper2">
            <div class="-hot-game-title-wrapper">
              <img
                alt="คาสิโนออนไลน์ ยอดนิยม"
                class="-title-bg img-fluid"
                width="200"
                height="71"
                src="@/assets/assets_np8/hot_slot_v2.png"
              />
              <!-- <span class="-title1">HOT SLOT</span> -->
            </div>
          </div>
          <ul class="nav nav-pills">
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big -cannot-entry -ma"
                  data-status="-cannot-entry -ma"
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/ezc-wt-pragmatic-play.webp" />
                      <source type="image/png" data-srcset="ezc-wt-pragmatic-play.png" />
                      <img
                        alt="wtm-bg cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/biggaming.png"
                        src="@/assets/gamesIcon/ezc-wt-pragmatic-play.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                          <a
                            href="/lobby/test?channelCode=wtm-bg"
                            class="-btn -btn-demo"
                            target="_blank"
                            rel="nofollow noopener"
                          >
                            <span class="-text-btn">ทดลองเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">Pragmatic Gaming</div>
                </div>
              </li>
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                  data-status=""
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/slot/ezc-wt-only-play.webp" />
                      <source
                        type="image/png"
                        data-srcset="@/assets/gamesIcon/slot/ezc-wt-only-play.png" />
                      <img
                        alt="wt-dream-gaming cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/slot/ezc-wt-only-play.png"
                        src="@/assets/gamesIcon/slot/ezc-wt-only-play.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                          <a
                            href="/lobby/test?channelCode=wt-dream-gaming"
                            class="-btn -btn-demo"
                            target="_blank"
                            rel="nofollow noopener"
                          >
                            <span class="-text-btn">ทดลองเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">OnlyPlay</div>
                </div>
              </li>
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big"
                  data-status=""
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/slot/ezc-wt-rich88.webp" />
                      <source
                        type="image/png"
                        data-srcset="@/assets/gamesIcon/slot/ezc-wt-rich88.png" />
                      <img
                        alt="wt-pretty-gaming cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/pgaming.png"
                        src="@/assets/gamesIcon/slot/ezc-wt-rich88.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                          <a
                            href="/lobby/test?channelCode=wt-pretty-gaming"
                            class="-btn -btn-demo"
                            target="_blank"
                            rel="nofollow noopener"
                          >
                            <span class="-text-btn">ทดลองเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">Rich 88</div>
                </div>
              </li>
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big -cannot-entry -untestable"
                  data-status="-cannot-entry -untestable"
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <div class="x-game-badge-image -big">
                      <img
                        alt="Provider ic master key"
                        class="-img img-fluid"
                        width="50"
                        height="60"
                        src="@/assets/gamesIcon/ajax-ic-master-key.png"
                      />
                    </div>
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/ezc-wt-ameba.webp" />
                      <source
                        type="image/png"
                        data-srcset="@/assets/gamesIcon/ezc-wt-ameba.png" />
                      <img
                        alt="wtm-asia-gaming cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/ezc-wt-ameba.png"
                        src="@/assets/gamesIcon/ezc-wt-ameba.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">Ameba Gaming</div>
                </div>
              </li>
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big -cannot-entry -untestable"
                  data-status="-cannot-entry -untestable"
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <div class="x-game-badge-image -big">
                      <img
                        alt="Provider ic master key"
                        class="-img img-fluid"
                        width="50"
                        height="60"
                        src="@/assets/gamesIcon/ajax-ic-master-key.png"
                      />
                    </div>
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/ezc-wtm-endorphina.webp" />
                      <source
                        type="image/png"
                        data-srcset="@/assets/gamesIcon/ezc-wtm-endorphina.png" />
                      <img
                        alt="wtm-asia-gaming cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/ezc-wtm-endorphina.png"
                        src="@/assets/gamesIcon/ezc-wtm-endorphina.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">Endorphina Gaming</div>
                </div>
              </li>
              <li class="nav-item -game-casino-macro-container-hot">
                <div
                  class="x-game-list-item-macro-in-share js-game-list-toggle -big -cannot-entry -untestable"
                  data-status="-cannot-entry -untestable"
                >
                  <div class="-inner-wrapper"
                  onclick="window.open('https://np8.co/login');" style="cursor: pointer;">
                    <div class="x-game-badge-image -big">
                      <img
                        alt="Provider ic master key"
                        class="-img img-fluid"
                        width="50"
                        height="60"
                        src="@/assets/gamesIcon/ajax-ic-master-key.png"
                      />
                    </div>
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="@/assets/gamesIcon/ezc-wt-pg-soft.webp" />
                      <source
                        type="image/png"
                        data-srcset="@/assets/gamesIcon/ezc-wt-pg-soft.png" />
                      <img
                        alt="wtm-asia-gaming cover image png"
                        class="img-fluid lazyload -cover-img"
                        width="364"
                        height="231"
                        data-src="@/assets/gamesIcon/ezc-wt-pg-soft.png"
                        src="@/assets/gamesIcon/ezc-wt-pg-soft.png"
                    /></picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a
                            href="#loginModal"
                            class="js-account-approve-aware -btn -btn-play"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-title">PG SOFT</div>
                </div>
              </li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import randomIconWebp from "@/assets/gamesIcon/random.webp";
export default {
  data() {
    return {
      randomIconWebp: randomIconWebp,
    };
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .-games-provider-wrapper .-games-index-page {
    flex: 0 0 80%;
    transition: all 0.4s;
   
  }
}

@media (min-width: 768px) {
  .-games-provider-wrapper .-games-index-page {
    padding-top: 1rem;
  }
}

.-heading-sub-title {
  margin-top: 3rem;
  padding: 0 1rem;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}
.h2,
h2 {
  font-size: 2rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

*,
:after,
:before {
  box-sizing: border-box;
}

div {
  display: block;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.x-category-provider {
  min-height: 500px;
  height: auto;
}

.x-category-provider .-games-list-container {
  padding: 0 1rem 2rem;
}

.x-category-provider .-games-list-container .nav-menu {
  overflow: hidden;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

dl,
ol,
ul {
  margin-top: 0;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.x-category-provider
  .-games-list-container
  .nav
  .nav-item.-game-casino-macro-container {
  padding: 0 0.5rem;
  margin: 1rem 0 0.25rem;
  width: 25%;
  -webkit-filter: none;
  filter: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.x-category-provider
  .-games-list-container
  .nav
  .nav-item.-random-container
  .nav-link {
  position: relative;
}

.x-category-provider .-games-list-container .nav .nav-item .nav-link {
  padding: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.x-category-provider
  .-games-list-container
  .nav
  .nav-item.-random-container
  .-text-nav-menu {
  margin-top: 0.75rem;
}

@media (max-width: 767.98px) {
  .x-category-provider .-games-list-container .nav .nav-item .-text-nav-menu {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .x-category-provider .-games-list-container .nav {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (max-width: 767.98px) {
  .x-category-provider .-games-list-container {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 991.98px) {
  .x-category-provider .-games-list-container {
    padding-bottom: 1rem;
  }
}
.x-category-provider .-games-list-container .nav .nav-item .-text-nav-menu {
  margin-top: 0.75rem;
  margin-bottom: 0;
  padding: 0;
  color: #c4c4c4;
  background: transparent;
  border-radius: 0;
}

.x-game-list-item-macro-in-share .-inner-wrapper {
  position: relative;
  padding: 0;
}

@media (max-width: 767.98px) {
  .x-index-top-container .-heading-sub-title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 991.98px) {
  .x-index-top-container .-heading-sub-title {
    font-size: 1.125rem;
  }
}

.x-index-top-container .-heading-sub-title {
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}

.h2,
h2 {
  font-size: 2rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
@media (max-width: 767.98px) {
  .x-category-provider
    .-games-list-container
    .nav
    .nav-item.-game-casino-macro-container
    .-img-provider {
    border-radius: 10px;
  }
}

.x-category-provider
  .-games-list-container
  .nav
  .nav-item.-game-casino-macro-container
  .-img-provider {
  border-radius: 15px;
}

/* img[Attributes style] {
  width: 334px;
  aspect-ratio: auto 334 / 164;
  height: 164px;
} */

.x-game-badge-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 70px;
  height: 70px;
  overflow: hidden;
}

.x-game-badge-image:before {
  content: "";
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 110px;
  height: 28px;
  background: rgba(0, 0, 0, 0.4);
}

.x-game-badge-image .-img {
  z-index: 1;
  width: 18px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.x-game-badge-image .-img,
.x-game-badge-image:before {
  position: absolute;
  top: 32%;
  left: 32%;
}

img {
  border-style: none;
}

/* img[Attributes Style] {
  width: 50px;
  aspect-ratio: auto 50 / 60;
  height: 60px;
} */

img,
svg {
  vertical-align: middle;
}

/* img[Attributes Style] {
  width: 364px;
  aspect-ratio: auto 364 / 231;
  height: 231px;
} */

.x-game-list-item-macro-in-share.-cannot-entry.-untestable .-btn-play {
  cursor: pointer;
  pointer-events: auto;
}

.x-game-list-item-macro-in-share.-cannot-entry .-btn-play {
  cursor: none;
  pointer-events: none;
}

.fa-play:before {
  content: "\f04b";
}

/**big img */
@media (max-width: 767.98px) {
  .x-category-provider
    .-games-list-container
    .nav
    .nav-item.-game-casino-macro-container:nth-child(3) {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .x-category-provider
    .-games-list-container
    .nav
    .nav-item.-game-casino-macro-container {
    width: 50%;
    padding: 0 0.375rem;
  }
}



.x-category-provider
  .-games-list-container
  .nav
  .nav-item.-game-casino-macro-container {
  padding: 0 0.5rem;
  margin: 1rem 0 0.25rem;
  /* width: 25%; */
  -webkit-filter: none;
  filter: none;
}

@media (max-width: 991.98px) {
  .x-category-provider .-games-list-container .nav .nav-item {
    margin-bottom: 1rem;
  }
}

.x-category-provider .-games-list-container .nav .nav-item {
  margin-bottom: 1.5rem;
  padding: 0 0.65rem;
}

.x-game-list-item-macro-in-share {
  position: relative;
}

.x-game-badge-component.-hot,
.x-game-badge-component.-new,
.x-game-badge-component.-popular {
  background: linear-gradient(180deg, #56ccf2, #2f80ed);
  color: #f3f3f3;
}

[data-animatable].animated {
  opacity: 1;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@media (max-width: 767.98px) {
  .x-game-badge-component {
    right: 10px;
    font-size: 0.875rem;
  }
}

@media (max-width: 991.98px) {
  .x-game-badge-component {
    top: unset;
    bottom: 8px;
    right: 8px;
    padding: 2px 6px;
    font-size: 1rem;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-animatable] {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .x-game-badge-component span {
    top: 0;
  }
}

.x-game-badge-component span {
  position: relative;
}

.x-game-list-item-macro-in-share .-inner-wrapper .-cover-video {
  display: block;
}

.x-game-badge-component {
  position: absolute;
  /* top: 12px;
    right: 12px; */
  z-index: 2;
  padding: 0 12px;
  border-radius: 5px;
}

.x-game-list-item-macro-in-share .-inner-wrapper .-cover-img {
  width: 100%;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

video {
  object-fit: contain;
}

.x-game-list-item-macro-in-share .-inner-wrapper .-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
  border-radius: 10px;
}

.x-game-list-item-macro-in-share.-big
  .-inner-wrapper
  .-overlay
  .-overlay-inner {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 1199.98px) {
  .x-game-list-item-macro-in-share .-inner-wrapper .-overlay .-overlay-inner {
    min-width: 120px;
  }
}

.x-game-list-item-macro-in-share .-inner-wrapper .-overlay .-overlay-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 150px;
  padding: 10px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn.-btn-play {
  position: relative;
  color: #fff;
  background: linear-gradient(180deg, #ef669e, #ed4f8f);
  overflow: hidden;
  font-weight: 500;
  font-size: 0.875rem;
  text-shadow: none;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn
  .fas {
  top: 1px;
  margin-right: 8px;
  font-size: 12px;
  -webkit-filter: none;
  filter: none;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

@media (max-width: 1199.98px) {
  .x-game-list-item-macro-in-share
    .-inner-wrapper
    .-overlay
    .-overlay-inner
    .-wrapper-container
    .-btn {
    min-width: 100px;
  }
}

a {
  color: #ed4f8f;
  text-decoration: none;
  background-color: transparent;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn
  .-text-btn,
.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn
  .fas {
  position: relative;
  z-index: 2;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn.-btn-demo {
  margin-top: 0.75rem;
  color: #fff;
  background: hsla(0, 0%, 100%, 0.3);
  border: 1px solid #fff;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 500;
  text-shadow: none;
}

.x-game-list-item-macro-in-share
  .-inner-wrapper
  .-overlay
  .-overlay-inner
  .-wrapper-container
  .-btn {
  padding: 10px;
  min-width: 120px;
  text-align: center;
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  transition: all 0.4s;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}

.-title{
  font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif !important; 
  font-weight:900;
}

@media (max-width: 767.98px) {
  .x-game-list-item-macro-in-share .-title {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}

.x-game-list-item-macro-in-share .-title {
  color: #f6f6f6;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.75rem;
  margin-bottom: 0;
  padding: 0 4px;
  background: transparent;
  border-radius: 0;
}

/** Hot */
@media (min-width: 350px) {
  .x-category-provider
    .-games-list-container
    .nav
    .nav-item.-game-casino-macro-container-hot {
    width: 50%;
    padding: 0 0.375rem;
  }
}
@media (max-width: 575.98px) {
  .x-category-provider
    .-games-list-container
    .nav
    .nav-item.-game-casino-macro-container-hot {
    margin-top: 0.2rem;
  }
}

/** Text */

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper {
    padding-left: 0.5rem;
  }
}
.nav-menu .-inner-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-menu .-inner-heading-wrapper .-hot-game-title-wrapper {
  position: relative;
  margin-left: 10px;
}

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper .-hot-game-title-wrapper .-title-bg {
    width: 160px;
  }
}

.nav-menu .-inner-heading-wrapper .-hot-game-title-wrapper .-title-bg {
  width: 200px;
  transition: all 0.4s;
}
.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}
img,
svg {
  vertical-align: middle;
}

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper .-hot-game-title-wrapper .-title {
    font-size: 1.125rem;
    margin-top: 4px;
    left: 50px;
  }
}
.nav-menu .-inner-heading-wrapper .-hot-game-title-wrapper .-title {
  margin-top: 2px;
  width: 250px;
  position: absolute;
  left: 0px;
  top: 55%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  transition: all 0.4s;
  font-size: 1.25rem;
}

@media (max-width: 575.98px) {
  .nav-menu .-inner-heading-wrapper .-title {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 991.98px) {
  .nav-menu .-inner-heading-wrapper .-title {
    font-size: 1.25rem;
  }
}

.nav-menu .-inner-heading-wrapper .-title {
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  background: linear-gradient(180deg, #fff 29.41%, #d6d6d6 85.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/**nice */

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper2 {
    padding-left: 0.5rem;
  }
}
@media (min-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper2 {
    padding-left: 0.5rem;
  }

}
.nav-menu .-inner-heading-wrapper2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-menu .-inner-heading-wrapper2 .-hot-game-title-wrapper {
  position: relative;
  margin-left: 10px;
}

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper2 .-hot-game-title-wrapper .-title1-bg {
    width: 160px;
  }
}

.nav-menu .-inner-heading-wrapper2 .-hot-game-title-wrapper .-title1-bg {
  width: 200px;
  transition: all 0.4s;
}

@media (max-width: 767.98px) {
  .nav-menu .-inner-heading-wrapper2 .-hot-game-title-wrapper .-title1 {
    font-size: 1.125rem;
    margin-top: 4px;
    left: 50px;
  }
}
.nav-menu .-inner-heading-wrapper2 .-hot-game-title-wrapper .-title1 {
  margin-top: 2px;
  width: 250px;
  position: absolute;
  left: 0px;
  top: 55%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  transition: all 0.4s;
  font-size: 1.25rem;
}

@media (max-width: 575.98px) {
  .nav-menu .-inner-heading-wrapper2 .-title1 {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 991.98px) {
  .nav-menu .-inner-heading-wrapper2 .-title1 {
    font-size: 1.25rem;
  }
}

.nav-menu .-inner-heading-wrapper2 .-title1 {
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  background: linear-gradient(180deg, #fff 29.41%, #d6d6d6 85.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px){


  .x-category-provider .-games-list-container .nav .nav-item.-game-casino-macro-container-hot {
    width:25%
  }
 
}


</style>
