<template>
  <div class="home">
    <nav
      class="x-header navbar navbar-expand-lg -anon x-bg-position-center -bg-image lazyloaded"
      data-bgset=""
    >
      <div class="container-fluid align-items-center h-100 position-relative">
        <div id="headerBrand">
          <a class="navbar-brand d-block d-lg-none" href="#">
            <img
              alt="Casino Slot เว็บพนันออนไลน์"
              class="-logo"
              width="55"
              height="55"
              src="@/assets/assets_np8/logo_horizontal.png"
            />
          </a>
        </div>
        <div id="headerContent">
          <ul class="nav -menu-wrapper">
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link"
                href="https://np8.co/login"
                target="blank"
                rel="noopener nofollow"
                data-toggle="modal"
                data-target="#loginModal"
              >
                <div class="-img-wrapper">
                  <img
                    alt="rich-gaming Image"
                    class="-icon"
                    width="38"
                    height="38"
                    src="@/assets/assets_np8/plan.png"
                  />
                </div>
                <div class="-text">กลยุทธ์</div>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <router-link class="nav-link" to="promotion">
                <div class="-img-wrapper">
                  <img
                    alt="rich-gaming Image"
                    class="-icon"
                    width="38"
                    height="38"
                    src="@/assets/assets_np8/promotion_tab.png"
                  />
                </div>
                <div class="-text">โปรโมชั่น</div>
              </router-link>
            </li>
          </ul>
          <div class="navbar-nav">
            <div class="img-box d-flex x-header-anon d-block d-md-none">
              <div class="onlineuserbox">
                <p class="elementToFadeInAndOut">{{ people }}</p>
              </div>
            </div>

            <div class="d-flex x-header-anon d-none d-md-block">
              <a
                class="btn -header-btn -default -login"
                href="https://np8.co/login"
                target="_blank"
                data-toggle="modal"
                data-target="#loginModal"
              >
                <span class="-text">เข้าสู่ระบบ</span>
              </a>
            </div>
            <div class="d-flex x-header-anon d-none d-lg-block">
              <a
                class="btn -header-btn -default -register"
                href="https://np8.co/register"
                data-toggle="modal"
                data-target="#loginModal"
                target="_blank"
              >
                <span class="-text">สมัครสมาชิก</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div id="main__content">
      <div class="x-main-container">
        <SideBar />
        <BodyContent />
      </div>
      <MenuFooter />
    </div>
  </div>
</template>

<script>
import MenuFooter from "../components/MenuFooter.vue";
import BodyContent from "../components/BodyContent.vue";
import SideBar from "../components/SideBar.vue";
export default {
  data() {
    return {
      people: "3000938",
    };
  },
  name: "HomeView",
  components: {
    SideBar,
    BodyContent,
    MenuFooter,
  },
  methods: {
    randomPeople() {
      let o = Math.floor(Math.random() * (900 - 100 - 1) + 100);

      let p = Math.floor(Math.random() * (900 - 100 - 1) + 100);
      this.people = "3," + o + "," + p + " ท่าน";
      return;
    },
  },
  mounted() {
    this.randomPeople();
    setInterval(() => {
      this.randomPeople();
    }, 8000);
  },
};
</script>

<style scoped>
.onlineuserbox p {
  position: absolute;
  display: block;
  top: 54%;
  left: 38%;
  color: white;
  font-size: 17px;
}
.img-box {
  
  background-image: url("@/assets/Untitled-2.gif");
  background-size: contain;
  background-repeat: no-repeat;
  width: 280px;
  height: 70px;
  background-position: center;
  position: relative;
}
@media (min-width: 501px) and (max-width: 700px) {
  .onlineuserbox p {
  
  top: 52%;
  left: 38%;
  
  font-size: 14px;
}
  .img-box {
    margin-right:10px;
    width: 220px !important;
    height: 80px !important;
  }

}
@media (max-width: 500px) {
  .img-box {
    margin-left:20px;
    width: 220px !important;
    height: 80px !important;
  }

  .onlineuserbox p {
  
  top: 52%;
  left: 38%;
  
  font-size: 14px;
}
}
@media (max-width: 415px){
  .img-box {
    margin-left:10px;
    width: 190px !important;
    height: 80px !important;
  }

  .onlineuserbox p {
  
  top: 53%;
  left: 38%;
  
  font-size: 12px;
}
}
.container-fluid {
  flex-wrap: nowrap !important;
}
.x-header.-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.x-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 75px;
  background: linear-gradient(180deg, #7b241c, #610b0b);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
.navbar {
  padding: 0.5rem 1rem;
}

.x-header #headerContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.x-header .-menu-wrapper .nav-item:not(:last-child) .nav-link {
  margin-right: 0.5rem;
}

/* media screen */

@media screen and(min-width: 992px) {
  .btn.-header-btn:hover {
    -webkit-filter: saturate(1.5);
    filter: saturate(1.5);
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 1199.98px) {
  .x-header .-menu-wrapper .nav-link {
    width: 70px;
  }

  .x-header .-menu-wrapper {
    margin-right: 0.4rem;
  }

  .x-header .navbar-brand .-logo {
    width: 200px;
  }

  .x-header .navbar-brand .-logo:hover {
    -webkit-filter: saturate(1.5);
    filter: saturate(1.5);
  }

  .x-header .-menu-wrapper .nav-link .-text {
    font-size: 0.75rem;
  }
  .btn.-header-btn .-text {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 1400px) {
  .x-header .navbar-brand .-logo {
    width: 55px;
  }
  .x-header .-menu-wrapper {
    margin-right: 0.6rem;
  }
}
dl,
ol,
ul {
  margin-top: 0;
}
.x-header .navbar-brand .-logo {
  width: 100px;
  height: auto;
}
img,
svg {
  vertical-align: middle;
}
.x-header .-menu-wrapper .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 80px;
  height: calc(100% + 4px);
  text-align: center;
  color: #f2f2f2;
  transition: 0.3s;
  padding: 0.5rem;
  background: linear-gradient(180deg, #0e2783, #0b132f);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
/* button login */

.x-header-anon {
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 0.5rem;
}
.btn.-header-btn.-default.-login:hover {
  outline: 0;
  box-shadow: 0 0 100px 0.2rem rgba(203, 49, 49, 0.459) inset;
}
.btn.-header-btn.-default.-register:hover {
  outline: 0;
  box-shadow: 0 0 100px 0.2rem rgba(92, 82, 238, 0.453) inset;
}
.btn.-header-btn.-default.-login {
  background-position: 50%;
  background-size: cover;
}
.btn.-header-btn.-default.-register {
  background-position: 50%;
  background-size: cover;
}
.btn.-header-btn.-default.-login {
  background-image: url("@/assets/assets_np8/button_1.png");
}
.btn.-header-btn.-default.-register {
  background-image: url("@/assets/assets_np8/button_2.png");
}
.btn.-header-btn.-default {
  padding: 1px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 20px;
}
.btn.-header-btn {
  position: relative;
  padding: 0;
  transition: all 0.3s;
  width: 140px;
  height: 40px;
}
.btn {
  padding: 0.75em 2em;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn.-header-btn .-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.btn:focus {
  box-shadow: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(237 79 143 / 25%);
}
.btn:hover {
  color: #fff;
  text-decoration: none;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.x-header .-menu-wrapper .nav-link:hover {
  color: #fff;
  background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}
.x-header .-menu-wrapper .nav-link .-img-wrapper:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 80%;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    #ff5959 0,
    rgba(255, 89, 220, 0) 100%
  );
  opacity: 0;
  -webkit-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
  transition: all 0.2s ease-in-out;
}
.x-header .-menu-wrapper .nav-link .-text {
  font-size: 0.875rem;
}

.x-header .-menu-wrapper .nav-link.router-link-active {
  color: #fff;
  background: linear-gradient(180deg, #f8f9fa, #ff6666 50.73%, #e2091f);
}

.elementToFadeInAndOut {
  opacity: 1;
  -webkit-animation: fadeinout 8s linear forwards infinite;
  animation: fadeinout 8s linear forwards infinite;
}

@-webkit-keyframes fadeinout {
  /* 0%,100% { opacity: 0; } 
  50% { opacity: 1; } */
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  /* 0%,100% { opacity: 0; }
  50% { opacity: 1; } */
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
