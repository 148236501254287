<template>
  <div>
    <div class="x-footer -ezc">
      <div class="container-fluid -footer-wrapper">
        <div class="-footer-inner-wrapper">
          <div class="-describe-wrapper">
            <div class="-content-wrapper js-replace-footer-title-seo-container">
              <div class="-content-wrapper">
                <h4 class="-title">NP8.BET คาสิโนออนไลน์ ที่ดีที่สุด</h4>
                <p class="-content">
                  เอาใจใส่ทุกคำพูดของท่านและพร้อมพัฆนาระบบของเราให้ดียิ่งขึ้นตลอดไป ทุกๆการเดิมพันของท่านจะปลอดภัยในใต้ความดูและของเรา ขอบคุณที่เลือกเรา NP8.BET
                </p>
              </div>
              <div class="-banner-icon-wrapper">
                <img
                  alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง"
                  class="img-fluid -ic-banner ls-is-cached lazyloaded"
                  width="162"
                  height="158"
                  data-src="@/assets/assets_np8/ez1.png"
                  src="@/assets/assets_np8/ez1.png"
                />
                <img
                  alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง"
                  class="img-fluid -ic-banner ls-is-cached lazyloaded"
                  width="162"
                  height="158"
                  data-src="@/assets/assets_np8/ez2.png"
                  src="@/assets/assets_np8/ez2.png"
                />
                <img
                  alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง"
                  class="img-fluid -ic-banner ls-is-cached lazyloaded"
                  width="162"
                  height="158"
                  data-src="@/assets/assets_np8/ez3.png"
                  src="@/assets/assets_np8/ez3.png"
                />
              </div>
            </div>
          </div>
          <div class="-tag-wrapper">
            <div class="-content-wrapper">
              <div class="-title">Tags</div>
              <div class="row x-footer-seo -ezc">
                <div class="col-12 mb-3"></div>
                <div class="col-12 -tags">
                  <a href="" class="btn btn-sm">บาคาร่า </a>
                  <a href="" class="btn btn-sm">คาสิโนออนไลน์ </a>
                  <a href="" class="btn btn-sm">บาคาร่าออนไลน์ </a>
                  <a href="" class="btn btn-sm">casino </a>
                  <a href="" class="btn btn-sm">ป๊อกเด้ง </a>
                  <a href="" class="btn btn-sm">เสือ มังกร </a>
                  <a href="" class="btn btn-sm">ออนไลน์ คาสิโน </a>
                  <a href="" class="btn btn-sm">wm casino </a>
                  <a href="" class="btn btn-sm">sexy gaming </a>
                  <a href="" class="btn btn-sm">dg casino </a>
                  <a href="" class="btn btn-sm">allbet </a>
                </div>
              </div>
            </div>
          </div>
          <div class="-provider-wrapper">
            <div class="px-3 text-center">
              <div class="-content-wrapper -partnership">
                <div class="-title">Partnerships</div>
                <div class="x-footer-banner-provider">
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-wm.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sa.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-allbet.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-sexy.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-ae.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-dream.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/partnership-logo-pretty.png"
                    /></picture>
                  </div>
                </div>
              </div>
              <div class="-content-wrapper -payments">
                <div class="-title">Payment Accepted</div>
                <div class="x-footer-banner-provider">
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bay.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-scb.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-bbl.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ktb.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kbank.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-kk.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-cimb.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-baac.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-gsb.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-sc.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-lhb.png"
                    /></picture>
                  </div>
                  <div class="-image-wrapper">
                    <picture
                      ><source
                        type="image/webp"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.webp"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.webp
                        " />
                      <source
                        type="image/png"
                        data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.png"
                        srcset="
                          https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.png
                        " />
                      <img
                        alt="เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์"
                        class="img-fluid -logo-ic ls-is-cached lazyloaded"
                        width="50"
                        height="50"
                        data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.png"
                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/payment-logo-ttb.png"
                    /></picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="-footer-bottom-wrapper">
          <div class="container">
            
          </div>
          <div>
            
            <p class="spacer">&nbsp;</p>
            <p class="spacer">&nbsp;</p>
            <p class="spacer">&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-main",
};
</script>

<style scoped>
@media (min-width: 992px) {
  .x-footer.-ezc {
    margin-left: 0px;
  }
  .spacer{
    display: none;
  }
}
@media (min-width: 998px) {
  .x-footer.-ezc {
    margin-left: 200px;
  }
}
@media (max-width: 1199.98px) {
  .x-footer.-ezc .-footer-inner-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
  .x-footer.-ezc .-footer-inner-wrapper .-content-wrapper {
    text-align: center;
  }
  .x-footer-banner-provider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-bottom: 20px;
}
.x-footer-seo.-ezc .-tags {
  border-bottom: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
}

}
@media (max-width: 1480px) {
  .x-footer.-ezc .-footer-inner-wrapper {
    justify-content: space-between;
  }
  .x-footer.-ezc .-footer-inner-wrapper .-describe-wrapper {
    margin: 0 auto;
  }
}

@media (min-width:1481px){

  .x-footer-banner-provider {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-bottom: 20px;
}
 
 .x-footer.-ezc {
  margin-left:250px;
 }


 

}

@media (min-width: 1200px) and (max-width: 1480px) {
  .x-footer.-ezc
    .-footer-inner-wrapper
    .-describe-wrapper
    .-content-wrapper
    .-title {
    font-size: 1rem;
  }
  .x-footer.-ezc
    .-footer-inner-wrapper
    .-describe-wrapper
    .-content-wrapper
    .-content {
    font-size: 1rem;
  }
  .x-footer.-ezc .-footer-inner-wrapper .-content-wrapper {
    text-align: left;
  }
  .x-footer-banner-provider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0.25rem;
}
.x-footer-seo.-ezc .-tags {
  border-bottom: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

}
.x-footer.-ezc
  .-footer-inner-wrapper
  .-describe-wrapper
  .-banner-icon-wrapper
  .-ic-banner {
  width: calc(33.33333% - 5px);
  max-width: 120px;
}
.x-footer.-ezc .-footer-inner-wrapper .-describe-wrapper .-banner-icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0 1rem;
}
.x-footer.-ezc
  .-footer-inner-wrapper
  .-describe-wrapper
  .-content-wrapper
  .-content {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.x-footer.-ezc
  .-footer-inner-wrapper
  .-describe-wrapper
  .-content-wrapper
  .-title {
  color: #74bede;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.5;
}

.x-footer.-ezc .-footer-inner-wrapper .-describe-wrapper .-content-wrapper {
  padding: 0 1rem;
margin-bottom: 20px;
}

.x-footer.-ezc .-footer-inner-wrapper .-describe-wrapper {
  min-width: 300px;
  transition: all 0.4s;
}
.x-footer.-ezc .-footer-inner-wrapper {
  display: flex;
  justify-content: space-evenly;
  transition: all 0.4s;
  padding: 2rem 1rem 1rem;
}
.x-footer {
  background: #000927;
}
.x-footer.-ezc .-footer-wrapper {
  display: flex;
  flex-direction: column;
  background: #000927;
  padding: 0;
}

.x-footer.-ezc .-footer-inner-wrapper {
  display: flex;
  justify-content: space-evenly;
  transition: all 0.4s;
  padding: 2rem 1rem 1rem;
}

.x-footer.-ezc .-footer-inner-wrapper .-content-wrapper .-content {
  margin-top: 0.25rem;
  color: #f3f3f3;
}
.x-footer.-ezc .-footer-inner-wrapper .-content-wrapper .-title {
    font-weight: 900;
  color: #74bede;
  font-size: 1.125rem;
}
.x-footer-seo.-ezc {
  padding: 0;
  margin: 0.25rem 0;
}

.x-footer-seo {
  text-align: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.x-footer-seo .-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #353535;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.x-footer-seo.-ezc .-tags .btn {
  font-size: 0.875rem;
  color: #fbfbfb;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 5px 15px;
  border-radius: 18px;
  background: #202020;
  transition: all 0.2s ease-in-out;
}
.x-footer-seo.-ezc .-tags .btn:hover {
  background: #333;
}

.x-footer-banner-provider .-image-wrapper {
  text-align: center;
  padding: 0.25rem;
}
.x-footer-banner-provider .-image-wrapper .-logo-ic {
  width: 36px;
  height: auto;
  border-radius: 10px;
}
.x-footer.-ezc .-footer-bottom-wrapper {
  padding-bottom: 0.75rem;
  text-align: center;
  color: #f3f3f3;
}
.x-contact-directly {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.x-contact-directly .-text-wrapper.-empty {
  text-align: center;
  margin-right: 0;
}

.x-contact-directly .-text-wrapper {
  margin-right: 1rem;
  font-weight: 300;
  color: #fff;
}
.x-contact-directly .-text-wrapper .-img-wrap-link {
  display: block;
  width: 160px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
}

.x-contact-directly .-text-wrapper .-img-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  font-size: 1.5rem;
  font-weight: 500;
}
.text-decoration-none {
  text-decoration: none !important;
}
.x-contact-directly .-text-wrapper .-img-wrap-link .-button-bg {
  width: 100%;
  height: auto;
  transition: all 0.2s;
}
.x-footer.-ezc .-footer-inner-wrapper {
  display: flex;
  justify-content: space-evenly;
  transition: all 0.4s;
  padding: 2rem 1rem 1rem;
}
.x-footer.-ezc .-footer-inner-wrapper .-provider-wrapper {
  justify-content: center;
}




</style>