<template>
 <div class="banner" >
<flickity class="carousel" ref="flickity" :options="flickityOptions">
  <div class="carousel-cell" ><img  src="@/assets/promotion/Auto.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/Certificate.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <!-- <div class="carousel-cell" ><img  src="@/assets/promotion/คืนยอดเสย.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/ชวนเพื่อน.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div> -->
  <div class="carousel-cell" ><img  src="@/assets/promotion/ถอน-10-ล้าน.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/ฝากแรก.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/เราเข้าใจคุณedit.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/วันเกีด.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/สมาชิกใหม่.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>


  <div class="carousel-cell" ><img  src="@/assets/promotion/Football-shirt.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/Ipad.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/Iphone.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/ค่าคอมมิชชั่นสมาชิก.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/ค่าคอมมิชชั้นหลักจากสมาชิกแนะนำเพื่อน.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/ชวนเพื่อน-2.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>

  <div class="carousel-cell" ><img  src="@/assets/promotion/Air-.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/Power-bank.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/headphone.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  <div class="carousel-cell" ><img  src="@/assets/promotion/JBL.png" alt="300รับ400" class="img-fluid -slick-item -item-6" width="100%" height="668px"></div>
  
</flickity>

 

 </div>
</template>

<script>
import Flickity from 'vue-flickity';
export default {

    name: 'banner-content',
    components: {
    Flickity
  },
  mounted(){
  setTimeout(() => {
    this.resize()
  }, 1000);
  },

    data(){
        return{
        
        sizedClass:"sizedClass",
        flickityOptions: {
          ImagesLoaded: true,
        initialIndex: 0,
        prevNextButtons:false,
        pageDots: true,
        wrapAround: true,
        autoPlay: true,
        freeScroll: true,
        pauseAutoPlayOnHover: false
        // any options from Flickity can be used
      }
        }
    },
    methods: {
      resize(){
        this.$refs.flickity.resize();
      },
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
  }
};
</script>

<style scoped>

.gallery-cell {
  height: 100%;
  width: 100%;
}


@media (max-width:538px){
.banner{
  margin-top:30px;
  margin-left: 0px;
}

.carousel-cell img {
  display: block;
  height: auto;
  border-radius: 10px;

}
.carousel-cell {
  width: 90%;
  height: auto;
  margin-right: 30px;
  border-radius: 5px;

}
.carousel:after, .carousel:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 0px;
    height: 0px;

}
}
@media (min-width:539px){
.banner{
  margin-top:30px;
  margin-left: 0px;
}

.carousel-cell img {
  display: block;
  height: auto;
  border-radius: 10px;

}
.carousel-cell {
  width: 70%;
  height: auto;
  margin-right: 30px;
  border-radius: 5px;

}

}
@media (min-width:769px){
.banner{
  margin-top:30px;
  margin-left: 0px;
}

.carousel-cell img {
  display: block;
  height: auto;
  border-radius: 10px;

}
.carousel-cell {
  width: 70%;
  height: auto;
  margin-right: 30px;
  border-radius: 5px;

}


}
@media(min-width:991.98px){
    .banner{
  margin-top:30px;
  margin-left: 200px;
}
.carousel-cell {
  width: 50%;
  height: 300px;
  margin-right: 10px;

  border-radius: 5px;

}
.carousel-cell img {
  display: block;
  height: 300px;
  border-radius: 10px;

}
.carousel:after, .carousel:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 150px;
    height: 300px;

}
}



/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;

  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel:after {
    right: 0;
    background: linear-gradient(270deg,rgba(0,0,0,.8),transparent);
}
.carousel:before {
    left: 0;
    background: linear-gradient(90deg,rgba(0,0,0,.8),transparent);
}



@media (min-width:1481px){
.banner{
  margin-top:30px;
  margin-left: 250px;
}
}

@media (min-width:1600px){

  .carousel-cell {
  width: 60%;
  height: 450px;
  margin-right: 10px;

  border-radius: 5px;

}

  .carousel-cell img {
  display: block;
  height:450px;
  border-radius: 10px;

}
.carousel:after, .carousel:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 150px;
    height: 450px;

}
}

@media (min-width:1900px){

  .carousel-cell {
  width: 60%;
  height:500px;
  margin-right: 10px;

  border-radius: 5px;

}

  .carousel-cell img {
  display: block;
  height:500px;
  border-radius: 10px;

}
.carousel:after, .carousel:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 150px;
    height: 450px;

}
}

</style>