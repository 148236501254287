<template>
  <div class="live-feed">
    <div class="-live-transaction-title-wrapper container">
      <div class="-withdraw-title-mobile-view">
        <div class="-title-container">
          <picture
            ><source
              type="image/webp"
              srcset="
                https://richgaming.co/build/web/ezc-rich-gaming/img/withdrawal-transaction-in-out-mobile.webp
              " />
            <source
              type="image/png?v=6"
              srcset="
                https://richgaming.co/build/web/ezc-rich-gaming/img/withdrawal-transaction-in-out-mobile.webp
              " />
            <img
              alt="รูปไอคอนบิลถอนและฝาก"
              class="-ic-img"
              width="30"
              height="21"
              src="https://richgaming.co/build/web/ezc-rich-gaming/img/withdrawal-transaction-in-out-mobile.webp"
          /></picture>
          <span class="-ic-text">บิลถอนล่าสุด</span>
        </div>
      </div>
    </div>
    <div class="-content d-flex justify-content-center elementToFadeInAndOut">
      <div class="-box" v-for="item in card" :key=item.id :class="`box_${item.id}`">
        <div class="-profile-wrapper">
          <div class="-profile-img-wrapper">
            <img
              class="img-fluid -profile-img"
              src="https://richgaming.co/build/web/ezc-rich-gaming/img/profile-icon-level-04.png?v=6"
              alt="บาคาร่า คาสิโน สล็อตออนไลน์ รูปประจำตัวลูกค้า"
              width="50"
              height="50"
            />
          </div>
          <div class="-profile-detail-wrapper">
            <div class="-profile-number">{{item.phone }}<span>XXXX</span></div>
            <div class="-detail-wrapper">
              <span class="-text"
                >{{ item.minutes }}</span
              >
            </div>
          </div>
        </div>
        <div class="-volume-wrapper">
          <picture
            ><source
              type="image/webp"
              srcset="
                https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/withdrawal-body-ic-coin.webp
              " />
            <source
              type="image/png"
              srcset="
                https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/withdrawal-body-ic-coin.png
              " />
            <img
              alt="รูปไอคอนเหรียญ"
              class="-ic-img"
              width="30"
              height="24"
              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/withdrawal-body-ic-coin.png"
          /></picture>
          <span class="-amount">{{ item.coin }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 /* eslint-disable */
export default {
  data(){
    return{
      timer:null,
      card:[{
        id:1,
        phone:"094587 ",
        coin:"200",
        minutes:"1:32นาที"
      },
      {
        id:2,
        phone:"093590 ",
        coin:"250",
        minutes:"4:01นาที"
      },
      {
        id:3,
        phone:"098712 ",
        coin:"400",
        minutes:"5:26นาที"
      },
      {
        id:4,
        phone:"092312 ",
        coin:"570",
        minutes:"8:42นาที"
      }
    ]
    }
  },

  mounted(){

    for(let data of this.card){
      
      data["phone"] = this.randomPhonenumber();
      data["coin"] = this.randomCoin();
    data["minutes"] = this.randomTime();
     
    }

    setInterval(() => {
      for(let data of this.card){
      
      data["phone"] = this.randomPhonenumber();
      data["coin"] = this.randomCoin();
    data["minutes"] = this.randomTime();
     
    }
    }, 8000);
    

  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods:{
    randomPhonenumber(){
      let baseNumber = "09";
      let randomNumber = Math.floor(Math.random()*1000);
      return baseNumber + randomNumber+ " ";
    },
    randomCoin(){
      return Math.floor((Math.random()*9989))+100;
    },
    randomTime(){
      let minute = Math.floor((Math.random()*14)) +10
      let second = Math.floor((Math.random()*50)) +10
      return minute +":"+ second+"นาที"
    },
 
  }
};
</script>

<style scoped>
.live-feed{
  padding-bottom: 10px;
  padding-top: 20px;
  background-color: #0b112d;
}
.-box {
  background: hsl(0, 80%, 21%);
  border-radius: 10px;
  margin: 3px 3px;
  padding: 0.5rem 0.75rem;
}
.-box .-profile-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.-profile-number{
  font-weight: 600;
  color: #fff;
 }
 .-detail-wrapper .-text{
  font-weight: 600;
  color: #fff;
 }
 .-amount{
  font-weight: 600;
  color: #fff;
 }

@media screen and (min-width:992px){
  .live-feed{
    margin-left: 170px;
  }
  .-box {
    padding: 0.75rem;
  }
  .-box .-volume-wrapper {
    padding: 0.5rem 0.75rem;
  }
}

@media (max-width: 1199.98px) {
  .-live-transaction-wrapper .-live-transaction-title-wrapper {
    margin-right: 0;
    width: auto;
    min-width: auto;
  }
  .-withdraw-title-mobile-view {
    display: block;
  }
  .-box {
    padding: 0.75rem;
  }
  .-box .-volume-wrapper {
    padding: 0.5rem 0.75rem;
  }
}

@media screen and(max-width: 575.98px) {
 
  .-box .-volume-wrapper {
    padding: 0.5rem 0.75rem;
  }
  .-profile-number{
  font-size: 0.25rem !important;
 }



}

@media (max-width:652px){

  .box_4{
    display: none !important;
   }

  .-box .-profile-wrapper .-profile-img-wrapper .-profile-img {
    width: 26px !important;
  }
  .-ic-img{
    width: 26px !important;
  }
  .-profile-number{
  font-size: 0.55rem;
  font-weight: 600;
  color: #fff;
 }
 .-detail-wrapper .-text{
  font-size: 0.75rem;
  font-weight: 600;
  color: #fff;
 }


}

@media (max-width: 767.98px) {
  .-withdraw-title-mobile-view .-title-container {
    margin-bottom: 1rem;
  }
}

@media (max-width: 991.98px) {
  .-box .-profile-wrapper .-profile-img-wrapper .-profile-img {
    width: 36px;
  }
  .-box .-volume-wrapper {
    padding: 0.5rem 0.75rem;
  }
  
}
.-withdraw-title-mobile-view .-title-container {
  width: 170px;

  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: linear-gradient(180deg, #e21919, rgba(85, 25, 102, 0));
  text-align: center;
}

.-withdraw-title-mobile-view .-title-container .-ic-text {
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
}

.-live-transaction-wrapper .-live-transaction-title-wrapper {
  width: 16vw;
  margin-right: 1rem;
  min-width: 292px;
  transition: all 0.4s;
}

.-box .-profile-wrapper .-profile-detail-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.-box .-volume-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: hsla(0, 0%, 76.9%, 0.15);
  border-radius: 30px;
}

.elementToFadeInAndOut {
    opacity: 1;
    -webkit-animation: fadeinout 8s linear forwards infinite;
    animation: fadeinout 8s linear forwards infinite;
   
}

@-webkit-keyframes fadeinout {
  /* 0%,100% { opacity: 0; } 
  50% { opacity: 1; } */
  0% {
    opacity:0;
  }
  20% {
    opacity:1;
  }
  80% {
    opacity:1;
  }
  100%{
    opacity:0;
  }
}

@keyframes fadeinout {
  /* 0%,100% { opacity: 0; }
  50% { opacity: 1; } */
  0% {
    opacity:0;
  }
  20% {
    opacity:1;
  }
  80% {
    opacity:1;
  }
  100%{
    opacity:0;
  }
}

</style>