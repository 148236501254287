<template>
  <div class="x-menu-footer -curve">
    <div class="-outer-wrapper">
      <div class="-left-wrapper">
        <a
          href="https://lin.ee/BDvR8q2"
          target="_blank"
          class="-item-wrapper -line"
       
          rel="nofollow noopener"
        >
          <img
            alt="ติดต่อเรา เว็บพนันออนไลน์ คาสิโนออนไลน์"
            class="-ic-img"
            id="line_image"
            width="34"
            height="34"
            src="@/assets/assets_np8/line_new_icon.png"
          
          />
          <span class="-text d-none d-sm-inline-block" style="font-size:12px">LINE</span>
          <span class="-text d-sm-none" style="font-size:12px">LINE</span>
        </a>
        <a @click="$router.push('promotion').catch(()=>{})" class="-item-wrapper -promotion">
          <img
            alt="โปรโมชั่น เว็บพนันออนไลน์ คาสิโนออนไลน์"
            class="-ic-img"
            width="34"
            height="34"
            src="@/assets/assets_np8/promotion_new_icon.png"
          />
          <span id="regis-button" class="-text d-none d-sm-inline-block" style="font-size:12px">โปรโมชั่น</span>
          <span class="-text d-sm-none" style="font-size:12px">โปรโมชั่น</span>
        </a>
      </div>
      <a @click="$router.push('/popular').catch(()=>{})" class="-center-wrapper -center">
        <div class="-selected">
          <img
            alt="คาสิโนออนไลน์ อันดับ 1"
            class="-ic-center"
            width="80"
            height="90"
            src="@/assets/assets_np8/logo_no_text.png"
          />
          
        </div>

      </a>
      <div class="-fake-center-bg-wrapper">
        <svg viewBox="-10 -1 30 12">
          <defs>
            <linearGradient
              id="rectangleGradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="20%" stop-color="#2A0A0A"></stop>
              <stop offset="100%" stop-color="#610B0B"></stop>
            </linearGradient>
          </defs>
          <path
            d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"
          ></path>
        </svg>
      </div>
      <ul class="navbar-nav -sub-menu-lobby-wrapper">
        <li class="nav-item -homepage">
          <a href="/" class="nav-link -homepage">
            <img
              alt="rich-gaming Image"
              class="-img"
              src="https://joker.bet/build/web/sa-joker/img/joker123-3.png"
            />
            <span class="-text">หน้าแรก</span>
          </a>
        </li>
        <li class="nav-item -baccarat">
          <button
            type="button"
            class="nav-link -baccarat"
            data-ajax-game-load="/_ajax_/%E0%B8%9A%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%A3%E0%B9%88%E0%B8%B2"
            data-target=".js-game-container"
            data-href-push-state="/%E0%B8%9A%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%A3%E0%B9%88%E0%B8%B2"
            data-menu-container=".js-menu-container"
            data-menu-mobile-container=".js-menu-mobile-container"
            data-menu-mobile="data-menu-mobile"
            data-category="บาคาร่า"
            data-loading="_onLoading_"
          >
            <img
              alt="rich-gaming Image"
              class="-img"
              src="https://joker.bet/build/web/sa-joker/img/joker123-3.png"
            />
            <span class="-text">บาคาร่า</span>
          </button>
        </li>
        <li class="nav-item -login">
          <a
            href="http://np8.co/login"
            target="_blank"
            data-toggle="modal"
            data-target="#loginModal"
            class="nav-link -login"
          >
            <img
              alt="rich-gaming Image"
              class="-img"
              src="https://joker.bet/build/web/sa-joker/img/joker123-3.png"
            />
            <span class="-text" >เข้าสู่ระบบ</span>
          </a>
        </li>
      </ul>
      <div class="-right-wrapper">
        <button
          type="button"
          class="btn -item-wrapper -register -shimmer"
          data-toggle="modal"
          data-target="#registerModal"
          onclick="window.open('https://np8.co/register');" style="cursor: pointer;"
        >
          <img
            alt="สมัครสมาชิก เว็บพนันออนไลน์ คาสิโนออนไลน์"
            class="-ic-img"
            width="34"
            height="34"
            src="@/assets/assets_np8/register_new_icon.png"
          />
          <span class="-text d-none d-sm-inline-block" style="font-size:12px;font-weight: 900 !important;">สมัครสมาชิก</span>
          <span class="-text d-sm-none" style="font-size:12px;font-weight: 900 !important;">สมัครสมาชิก</span>
        </button>
        <button
          type="button"
          class="btn -item-wrapper -login"
          data-toggle="modal"
          data-target="#loginModal"
          onclick="window.open('https://np8.co/login');" style="cursor: pointer;"
        >
          <img
            alt="สมัครสมาชิก เว็บพนันออนไลน์ คาสิโนออนไลน์"
            class="-ic-img"
            width="34"
            height="34"
            src="@/assets/assets_np8/login_new_icon.png"
          />
          <span class="-text d-none d-sm-inline-block" style="font-size:12px">เข้าสู่ระบบ</span>
          <span class="-text d-sm-none" style="font-size:12px">เข้าสู่ระบบ</span>
        </button>
      </div>
      <div class="-fully-overlay js-footer-lobby-overlay"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scope>
@media (min-width: 992px){
.x-menu-footer {
    display: none;
}
}

.x-menu-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    width: 100%;
}
.x-menu-footer.-curve .-outer-wrapper {
    display: flex;
    align-items: flex-end;
}
.x-menu-footer.-curve .-left-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 22px;
}
.x-menu-footer.-curve .-left-wrapper, .x-menu-footer.-curve .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg, #2A0A0A, #610B0B);
    transition: -webkit-filter .2s;
    transition: filter .2s;
    transition: filter .2s,-webkit-filter .2s;
}
.x-menu-footer.-curve .-center-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    width: 80px;
    height: 80px;
    margin: 0 8px 14px;
    padding-bottom: 0px;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px hsl(0deg 0% 100% / 40%) inset;
    
} 
.x-menu-footer.-curve .-center-wrapper:hover{
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

a {
    color: #ed4f8f;
    text-decoration: none;
    background-color: transparent;
}
.x-menu-footer.-curve .-fake-center-bg-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    transition: -webkit-filter .2s;
    transition: filter .2s;
    transition: filter .2s,-webkit-filter .2s;
    overflow: hidden;
}
.x-menu-footer.-curve .-sub-menu-lobby-wrapper {
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: -75px;
    left: 50%;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    -webkit-transform: translateX(-50%) translateY(200px) scaleY(2.5) scaleX(.2);
    transform: translateX(-50%) translateY(200px) scaleY(2.5) scaleX(.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: all .2s;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
dl, ol, ul {
    margin-top: 0;
}
address, dl, ol, ul {
    margin-bottom: 1rem;
}
.x-menu-footer.-curve .-right-wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 22px;
}

.x-menu-footer.-curve .-left-wrapper, .x-menu-footer.-curve .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg, #2A0A0A, #610B0B);
    transition: -webkit-filter .2s;
    transition: filter .2s;
    transition: filter .2s,-webkit-filter .2s;
}
.x-menu-footer.-curve .-fully-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0,0,0,.6);
}
.x-menu-footer.-curve .-item-wrapper {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding: 0;
    color: #fff;
}
.x-menu-footer.-curve .-center-wrapper .-selected {
    transition: all .4s;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.x-menu-footer.-curve .-center-wrapper .-selected, .x-menu-footer.-curve .-center-wrapper .-selecting {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}
.x-menu-footer.-curve .-center-wrapper .-selecting {
    display: none;
}

.x-menu-footer.-curve .-center-wrapper .-selected, .x-menu-footer.-curve .-center-wrapper .-selecting {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

.x-menu-footer.-curve .-item-wrapper.-shimmer .-text {
    display: inline-block;
    color: #fff;
    background: #fff -webkit-gradient(linear,100% 0,0 0,from(#fff),color-stop(.5,#ed4f4f),to(#fff));
    background-position: -200px top;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation-name: x-shimmer;
    animation-name: x-shimmer;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: x-shimmer-shorter;
    animation-name: x-shimmer-shorter;
}

@keyframes x-shimmer-shorter {
		0% {
            background-position: -60px top;
		}
		100% {
            background-position: 60px top;
		}
}

@keyframes x-register-bottom-blink {
    0% {
    -webkit-filter: drop-shadow(0 0 3px #56e8ff);
    filter: drop-shadow(0 0 3px #56e8ff);
}

100% {
    -webkit-filter: drop-shadow(0 0 6px #56e8ff);
    filter: drop-shadow(0 0 6px #56e8ff);
}
}
.x-menu-footer.-curve .-fake-center-bg-wrapper svg {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 108px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.x-menu-footer.-curve .-outer-wrapper .-left-wrapper .-item-wrapper.-line .-ic-img{
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}


.x-menu-footer.-curve .-item-wrapper.-register .-ic-img {
    -webkit-filter: drop-shadow(0 0 3px #56e8ff);
    filter: drop-shadow(0 0 3px #56e8ff);
    -webkit-animation: x-register-bottom-blink .5s infinite alternate;
    animation: x-register-bottom-blink .5s infinite alternate;
}

.x-menu-footer.-curve .-item-wrapper.-register {
    position: relative;
}

.x-menu-footer.-curve .-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding: 0;
    color: #fff;
}
.x-menu-footer.-curve .-fake-center-bg-wrapper svg path {
    fill: url(#rectangleGradient);
}

</style>