/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SlotComp from '../components/gamesComp/SlotComp.vue'
import GameIndexPage from '../components/GameIndexPage.vue'
import LongSkillGame from '../components/gamesComp/LongSkillGame.vue'
import GamePromotions from '../components/GamePromotions'
import PopularComp from '../components/gamesComp/PopularComp.vue'
import ElectronicSport from '../components/gamesComp/ElectronicSport.vue'
import SoodComp from '../components/gamesComp/SoodComp.vue'
import CasinoComp from '../components/gamesComp/CasinoComp.vue'
import FishComp from '../components/gamesComp/FishComp.vue';
import EsportComp from '../components/gamesComp/EsportComp.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path:'',
        component:PopularComp,
       
      },
      {
        path:'casino',
        component:CasinoComp
      },
      {
        path:'popular',
        component:PopularComp
      },
      {
        path:'slot',
        component:SlotComp
      },
      {
        path:'skill',
        component:LongSkillGame,
      },
      {
        path:'promotion',
        component:GamePromotions,
      },
      {
        path:'sport',
        component:EsportComp,
      },
      {
        path:'esport',
        component:ElectronicSport,
      },
      {
        path:'fishing',
     //   component:PopularComp,
        component:FishComp,
      },
      {
        path:'sood',
      //  component:PopularComp,
        component:SoodComp,
      },
      {
        path:'*',
        component:PopularComp
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
